import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import Img from 'gatsby-image/withIEPolyfill'
import { useIntl } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import { Box } from '@material-ui/core'

import Headline from '@objects/headline'
import Copy from '@objects/copy'
import Button from '@objects/button'

const useStyles = makeStyles((theme) => ({
  fullwidthTeaserRoot: {
    ...theme.mixins.containerSpacing,
  },
  right: {
    [theme.breakpoints.up('lg')]: {
      order: 2,
      alignSelf: 'stretch',
    },
  },
  content: {
    padding: theme.spacing(10, 4, 0, 4),

    [theme.breakpoints.up('lg')]: {
      padding: ({ reverse }) =>
        reverse ? theme.spacing(23, 4, 25, 17) : theme.spacing(23, 17, 25, 4),
      marginLeft: ({ reverse }) => (reverse ? 'none' : 'auto'),
      maxWidth: '100%',
      width: `${parseInt(theme.container.lg, 10) / 2}px`,
    },
  },
  image: {
    height: '100%',
  },
  headline: {
    padding: theme.spacing(0, 4),
    marginBottom: theme.spacing(6),
    textAlign: 'center',
    lineHeight: '38px',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 15),
      marginBottom: theme.spacing(7),
    },
  },
  copy: {
    marginBottom: theme.spacing(8),
    ...theme.typography.teaser,
  },
}))

function FullwidthTeaser({
  className,
  image,
  headline,
  copy,
  button,
  reverse,
}) {
  const classes = useStyles({ reverse })
  const intl = useIntl()
  return (
    <Grid
      container
      className={clsx(classes.fullwidthTeaserRoot, className)}
      direction={reverse ? 'row-reverse' : 'row'}
      alignItems="center"
      role="region"
      aria-label={intl.formatMessage({
        id: reverse
          ? 'ariaLabel.fullwidthteaser.left'
          : 'ariaLabel.fullwidthteaser.right',
      })}
    >
      <Grid
        item
        className={classes.right}
        xs={12}
        lg={6}
        aria-label={intl.formatMessage({
          id: 'ariaLabel.fullwidthteaser.imagetile',
        })}
      >
        {image && (
          <Img
            className={classes.image}
            fluid={{ ...image.fluid, media: `(min-width: 0px)` }}
            backgroundColor={true}
            alt={image.description}
          />
        )}
      </Grid>
      <Grid item className={classes.left} xs={12} lg={6}
      aria-label={intl.formatMessage({
        id: 'ariaLabel.fullwidthteaser.texttile',
      })}
      >
        <div className={classes.content}>
          <Headline level={2} className={classes.headline}>
            {headline}
          </Headline>
          {copy && <Copy className={classes.copy} html={copy} />}
          <Box textAlign="center">
            <Button className={className} to={button?.link} type={'secondary'}>
              {button?.label}
            </Button>
          </Box>
        </div>
      </Grid>
    </Grid>
  )
}

FullwidthTeaser.propTypes = {
  className: PropTypes.string,
  image: PropTypes.object,
  headline: PropTypes.string.isRequired,
  copy: PropTypes.string,
  reverse: PropTypes.bool,
  button: PropTypes.shape({
    link: PropTypes.string,
    label: PropTypes.string,
  }),
}
export default FullwidthTeaser
