import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image/withIEPolyfill'
import { focalPointsTable } from '@tools/focalPoints'

function ParallaxImage({
  className,
  outerClassName,
  image,
  alt,
  scale,
  focalPoint,
}) {
  const CompRef = useRef()
  const ParallaxRef = useRef(null)
  const focalPoints = focalPointsTable

  useEffect(() => {
    return () => {
      if (ParallaxRef.current) ParallaxRef.current.destroy()
    }
  }, [])

  function onImageLoad() {
    if (!CompRef.current?.querySelector('img')) return
    import('simple-parallax-js')
      .then((simpleParallax) => {
        ParallaxRef.current = new simpleParallax.default(
          CompRef.current?.querySelector('img'),
          {
            scale: scale,
            customWrapper: '.gatsby-image-wrapper',
          }
        )
      })
      .catch((e) => console.error(e))
  }

  return (
    <div ref={CompRef} className={outerClassName}>
      <Img
        className={className}
        imgStyle={{
          transform: `scale(${(scale, scale)})`,
        }}
        fluid={{ ...image, media: `(min-width: 0px)` }}
        backgroundColor={true}
        onLoad={onImageLoad}
        alt={alt || ''}
        objectPosition={
          focalPoint ? focalPoints[focalPoint] : focalPoints['Center']
        }
      />
    </div>
  )
}

ParallaxImage.propTypes = {
  className: PropTypes.string,
  outerClassName: PropTypes.string,
  image: PropTypes.object,
  alt: PropTypes.string,
  scale: PropTypes.number,
  focalPoint: PropTypes.oneOf(Object.keys(focalPointsTable)),
}

ParallaxImage.defaultProps = {
  scale: 1.2,
}

export default ParallaxImage
