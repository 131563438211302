import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Headline from '@objects/headline'
import Copy from '@objects/copy'
import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  headline: {
    ...theme.typography.h3,
    position: 'relative',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
  },
  copy: {
    ...theme.typography.teaser,
  },
  icon: {
    verticalAlign: '-6px',
    marginRight: theme.spacing(3),
  },
}))

function TripleText({ className, items, color, center }) {
  const classes = useStyles()

  function renderTexts() {
    return items.map((item, i) => (
      <Grid key={`item${i}`} item xs={12} md={4} lg={3}>
        <Headline level={2} className={classes.headline}>
          <Icon
            className={classes.icon}
            name="Checkmark"
            color={color}
            size="inherit"
          />
          {item.headline}
        </Headline>
        <Copy className={classes.copy} html={item.copy} />
      </Grid>
    ))
  }

  return (
    <Grid
      container
      className={clsx(className, classes.root)}
      spacing={8}
      justify={center ? 'center' : 'space-around'}
    >
      {renderTexts()}
    </Grid>
  )
}

TripleText.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string.isRequired,
      copy: PropTypes.string,
    })
  ),
  color: PropTypes.oneOf(['primary', 'secondary']),
  center: PropTypes.bool,
}

TripleText.defaultProps = {
  color: 'secondary',
}

export default TripleText
