import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import { navigate } from 'gatsby'
import clsx from 'clsx'

import api from '@api'
import Topic from '@objects/topic'

function PostsFilter({
  className,
  children,
  renderLink,
  type,
  topicsArray,
  activeButton = () => {},
}) {
  const topicRequest = api().getTopics
  const [topics, setTopics] = useState([])
  const intl = useIntl()

  useEffect(() => {
    if (topicsArray) {
      setTopics(topicsArray)
    } else {
      topicRequest &&
        topicRequest()
          .then((response) => {
            setTopics(response.data)
          })
          .catch((err) => {
            console.error('topics error: ', err)
          })
    }
  }, [topicsArray])

  function handleTopicClick(buttonlink) {
    navigate(buttonlink, { replace: true })
  }

  return (
    <Grid
      container
      spacing={5}
      justify={type === 'sidebar' ? 'flex-start' : 'center'}
      className={className}
    >
      {topics && topics.length
        ? topics.map((topic) => {
            const buttonLink = renderLink
              ? renderLink(topic)
              : intl.formatMessage(
                  { id: 'forum.path.topics' },
                  { topics: topic.id }
                )
            const activeButtonClass = activeButton && activeButton(topic)

            return (
              <Grid item key={`topic-${topic.id}`}>
                <Topic
                  className={clsx({
                    active: activeButtonClass,
                  })}
                  onClick={() => handleTopicClick(buttonLink)}
                >
                  {topic.name}
                </Topic>
              </Grid>
            )
          })
        : null}
    </Grid>
  )
}

PostsFilter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  renderLink: PropTypes.func,
  activeButton: PropTypes.func,
  type: PropTypes.oneOf(['default', 'sidebar']),
  topicsArray: PropTypes.array,
}

PostsFilter.defaultProps = {
  type: 'default',
}

export default PostsFilter
