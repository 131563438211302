import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import clsx from 'clsx'

import api from '@api'
import PostCard from '@components/postCard'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: 0,
  },
  graphicTop: {
    position: 'absolute',
    top: 0,
    zIndex: 0,
    right: 'calc(-50vw)',
    transform: 'translateX(-90%)',
  },
  graphicBottom: {
    position: 'absolute',
    bottom: 0,
    zIndex: 0,
    left: 'calc(-50vw)',
    transform: 'translateX(90%)',
  },
  postRoot: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
    marginBottom: theme.spacing(10),
  },
  postRootLast: {
    marginBottom: theme.spacing(15),
  },
  postQuestion: {
    zIndex: 2,
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
  },
  postAnswer: {
    zIndex: 1,
    margin: theme.spacing(-3, 4, 0, 4),
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(4, 0, 4, -4),
      alignSelf: 'center',
      width: '50%',
    },
  },
}))

function LatestPosts({ className, children, baseLevel, ...props }) {
  const intl = useIntl()
  const postRequest = api().getPosts
  const classes = useStyles()
  const [posts, setPosts] = useState([])

  useEffect(() => {
    postRequest &&
      postRequest({ limit: 5, overviewModul: true })
        .then((response) => {
          setPosts(response.data.result)
        })
        .catch((err) => {
          console.error('posts error', err)
        })
  }, [])

  return (
    <div className={classes.root} data-testid={'latestPosts'} role="list">
      <img
        className={classes.graphicTop}
        src="/img/illustration/illustration_grey_diagonal.svg"
        alt=""
      />
      <img
        className={classes.graphicBottom}
        src="/img/illustration/illustration_grey_diagonal.svg"
        alt=""
      />
      {(posts.length &&
        posts.map((item, index) => {
          const questionComponent = (
            <PostCard
              className={classes.postQuestion}
              key={item.id}
              postData={{
                likeCount: item.numberOfLikes,
                replyCount:
                  item.numberOfUserAnswers + item.numberOfExpertAnswers,
                userName: item.userName,
                userColor: item.userColor,
                timestamp: item.timestamp,
                isDeleted: item.isDeleted,
                deleteType: item.deleteType,
                topics: item.topics,
                title: item.title,
                text: item.text,
                id: item.id,
              }}
              isWrapped={true}
              noMetaBar={true}
              textLink={`${intl.formatMessage(
                { id: 'forum.path.single' },
                { postId: item.id }
              )}?dialogueid=${item.id}`}
              type={'dialogue'}
              baseLevel={baseLevel}
              ariaLabel="ariaLabel.post.question"
            />
          )
          const postAnswer = item.answers ? item.answers[0] : null
          const answerComponent = postAnswer ? (
            <PostCard
              key={`${item.id}-answer`}
              className={classes.postAnswer}
              bar={'yellow'}
              background={'darkSmall'}
              postData={{
                likeCount: postAnswer.numberOfLikes,
                replyCount: postAnswer.comments.length,
                userName: postAnswer.userName,
                userColor: postAnswer.userColor,
                timestamp: postAnswer.timestamp,
                isDeleted: postAnswer.isDeleted,
                deleteType: postAnswer.deleteType,
                topics: postAnswer.topics,
                title: postAnswer.title,
                text: postAnswer.text,
                isExpert: postAnswer.isExpertAnswer,
                id: postAnswer.id,
                comments: postAnswer.comments,
              }}
              isWrapped={true}
              noMetaBar={true}
              textLink={`${intl.formatMessage(
                { id: 'forum.path.single' },
                { postId: item.id }
              )}?answerid=${postAnswer.id}`}
              type={'dialogue-answer'}
              interactive={false}
              baseLevel={baseLevel}
              ariaLabel="ariaLabel.post.answer"
            />
          ) : null

          return (
            <div
              className={clsx(classes.postRoot, {
                [classes.postRootLast]: posts.length === index + 1,
              })}
              key={`${item.id}-post`}
            >
              {questionComponent}
              {postAnswer && answerComponent}
            </div>
          )
        })) || <PostCard postData={{}} loading={true} baseLevel={baseLevel} />}
    </div>
  )
}

LatestPosts.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
}

LatestPosts.defaultProps = {}

export default LatestPosts
