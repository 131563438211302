import React from 'react'
import PropTypes from 'prop-types'
import useStaticModule from '@hooks/useStaticModule'
import { FormattedMessage, useIntl } from 'react-intl'

import { useTheme, makeStyles } from '@material-ui/core/styles'

import Container from '@objects/container'
import Headline from '@objects/headline'
import FullwidthTeaser from '@objects/fullwidthTeaser'
import TripleText from '@components/tripleText'
import Stage from '@components/stage'
import PostsFilter from '@components/posts/filter'
import LatestPosts from '@components/posts/latest'
import Button from '@objects/button'
import CreateContentButton from '@objects/button/createContentButton'

export const frontmatter = {
  header: {
    cta: 'createArticle',
  },
}

const useStyles = makeStyles((theme) => ({
  buttonBar: {
    textAlign: 'center',
    '& a, & button': {
      marginBottom: theme.spacing(30),
    },
  },
  allPostsButton: {
    marginRight: theme.spacing(8),
  },
  headline: {
    textAlign: 'center',
    marginTop: theme.spacing(24),
    marginBottom: theme.spacing(15),
  },
  topics: {
    textAlign: 'center',
  },
  infoPortalBottomTeaser: {
    [theme.breakpoints.up('lg')]: {
      marginBottom: 0,
    },
  },
}))

function RootIndex({ pageContext }) {
  const classes = useStyles()
  const intl = useIntl()
  const theme = useTheme()
  const { getStaticFieldValue, getStaticFieldMedia, getStaticFieldButton } =
    useStaticModule(pageContext.modules)

  const stage = {
    image: getStaticFieldMedia(
      'onlinedialog.stage',
      `onlinedialog.stage.image`
    ),
    headline: getStaticFieldValue(
      'onlinedialog.stage',
      `onlinedialog.stage.headline`
    ),
    copy: getStaticFieldValue('onlinedialog.stage', `onlinedialog.stage.copy`),
  }

  const tripleText = ['1', '2', '3'].map((count) => {
    return {
      headline: getStaticFieldValue(
        'onlinedialog.tripletext',
        `onlinedialog.tripletext.${count}.headline`
      ),
      copy: getStaticFieldValue(
        'onlinedialog.tripletext',
        `onlinedialog.tripletext.${count}.copy`
      ),
    }
  })

  const infoPortalBottomTeaser = {
    headline: getStaticFieldValue(
      'onlinedialog.bottom.teaser',
      `onlinedialog.bottom.teaser.headline`
    ),
    copy: getStaticFieldValue(
      'onlinedialog.bottom.teaser',
      `onlinedialog.bottom.teaser.copy`
    ),
    button: getStaticFieldButton(
      'onlinedialog.bottom.teaser',
      `onlinedialog.bottom.teaser.button`
    ),
    image: getStaticFieldMedia(
      'onlinedialog.bottom.teaser',
      `onlinedialog.bottom.teaser.image`
    ),
  }

  const CommunesBottomTeaser = {
    headline: getStaticFieldValue(
      'onlinedialog.bottom.teaser.communes',
      `onlinedialog.bottom.teaser.communes.headline`
    ),
    copy: getStaticFieldValue(
      'onlinedialog.bottom.teaser.communes',
      `onlinedialog.bottom.teaser.communes.copy`
    ),
    button: getStaticFieldButton(
      'onlinedialog.bottom.teaser.communes',
      `onlinedialog.bottom.teaser.communes.button`
    ),
    image: getStaticFieldMedia(
      'onlinedialog.bottom.teaser.communes',
      `onlinedialog.bottom.teaser.communes.image`
    ),
  }

  return (
    <>
      <Stage
        title={stage.headline}
        ariaLabel={stage.headline}
        copy={stage.copy}
        image={stage.image}
        cardBar="red"
        buttons={[
          {
            label: intl.formatMessage({ id: 'button.discussion' }),
            link: intl.formatMessage({ id: 'forum.path' }),
            color: 'red',
            type: 'secondary',
            icon: 'TextArrow',
          },
          {
            label: intl.formatMessage({ id: 'button.createArticle' }),
            color: 'outline',
            type: 'secondary',
            icon: 'TextArrow',
            create: true,
          },
        ]}
      />
      <Container ariaLabel="arialabel.onlinedialog">
        <TripleText items={tripleText} color="primary" />
      </Container>
      <Container width="sm" ariaLabel="arialabel.onlinedialog.populartopics">
        <Headline className={classes.headline} level={2}>
          Beliebte Themen
        </Headline>
        <PostsFilter className={classes.topics} />
      </Container>
      <Container
        style={{ overflow: 'hidden' }}
        width="lg"
        background={theme.palette.background.grey}
        type="nomargin"
        ariaLabel="arialabel.onlinedialog.currenttopics"
      >
        <Headline className={classes.headline} level={2}>
          <FormattedMessage id={'dialogue.currentPosts'} />
        </Headline>
        <LatestPosts baseLevel={2} />
        <div className={classes.buttonBar}>
          <Button
            className={classes.allPostsButton}
            link={intl.formatMessage({ id: 'forum.path' })}
            color={'red'}
            type={'secondary'}
          >
            <FormattedMessage id={'button.allPosts'} />
          </Button>
          <CreateContentButton color={'outline'} type={'secondary'}>
            <FormattedMessage id={'button.createArticle'} />
          </CreateContentButton>
        </div>
      </Container>
      <FullwidthTeaser
        className={classes.infoPortalBottomTeaser}
        {...infoPortalBottomTeaser}
      />
      <FullwidthTeaser reverse={true} {...CommunesBottomTeaser} />
    </>
  )
}

RootIndex.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default RootIndex
